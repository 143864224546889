<template>
    <div style="overflow-x: hidden;">
        <navBar nav="发布出售广告" />
        <van-cell title="选择币种" :value="coin" :is-link="true" @click="openCoin" />
        <van-cell title="所在地" :value="form.country" :is-link="true" @click="goCountry" />
        <van-field v-model="coinType" label="货币类型" placeholder="根据国家自动选择" disabled input-align="right" />
        <van-field v-model="marketPrices" label="价格" input-align="right">
            <template #extra>{{ coinType }}</template>
        </van-field>
        <div style="height: 12.7226vw;width: 95.4198vw;">
            <div
                style="width: 100.5089vw;height: 11.4504vw;padding-left: 3.8168vw;display: flex; justify-content: space-between; align-items: center; background-color: #fff;border-bottom: 1rpx solid #eee;">
                <span>价格类型</span>
                <van-radio-group v-model="form.priceType" direction="horizontal" @change="change">
                    <van-radio :name="0">固定价格</van-radio>
                    <van-radio :name="1">实时价格</van-radio>
                </van-radio-group>
            </div>
        </div>
        <van-field v-model="form.premiseRate" label="溢价" :placeholder="text" :disabled="show2" @input="input"
            input-align="right">
            <template #extra>%</template>
        </van-field>
        <van-field v-model="form.price" label="交易价格" :placeholder="priceText" type="number" :disabled="show6"
            input-align="right">
            <template #extra>{{ coinType }}</template>
        </van-field>
        <van-field v-model="form.number" label="卖出数量" placeholder="交易数量" type="number" input-align="right"></van-field>
        <div class="price">
            交易手续费(10.0)%: {{ form.number * 0.1 }}
        </div>
        <van-field v-model="form.minLimit" label="最小量" placeholder="每一笔交易的最小限额" type="number" input-align="right">
            <template #extra>{{ coinType }}</template>
        </van-field>
        <van-field v-model="form.maxLimit" label="最大量" placeholder="每一笔交易的最大限额" type="number" input-align="right">
            <template #extra>{{ coinType }}</template>
        </van-field>
        <van-cell title="收款方式" :value="pay.length == 0 ? payText : `${pay + ' '}`" :is-link="true" @click="choosePay" />
        <van-cell title="付款期限" :value="`${form.timeLimit}${form.timeLimit == '请选择付款期限' ? '' : '分钟'}`"
            @click="chooseTimeS" />
        <van-field v-model="form.jyPassword" label="资金密码" placeholder="请输入资金密码" type="password"
            input-align="right"></van-field>
        <div style="margin: 4.0712vw;">
            <van-button round block type="info" @click="submit">发布</van-button>
        </div>
        <van-popup v-model="show1" position="bottom" :style="{ height: '14%' }">
            <div class="coin" v-for="item in marketPrice" @click="chooseCoin(item)">{{ item.name }}</div>
            <div class="coin" style="border: none" @click="show1 = false">取消</div>
        </van-popup>
        <van-popup v-model="show3" position="bottom" :style="{ height: '30%' }">
            <div class="coin" v-for="item in payType" @click="choosePayType(item.name)">{{ item.name }}</div>
            <div class="coin" style="border: none" @click="show3 = false">取消</div>
        </van-popup>
        <van-popup v-model="show4" position="bottom" :style="{ height: '30%' }">
            <div class="coin" v-for="item in time" @click="chooseTime(item.name)">{{ item.name }}分钟</div>
            <div class="coin" style="border: none" @click="show4 = false">取消</div>
        </van-popup>
    </div>
</template>

<script>
import navBar from "../../components/navBar/index.vue"
import store from "../../store/otcStore"
export default {
    data() {
        return {
            form: {
                priceType: 0,
                price: '',
                country: '所在地',
                timeLimit: '请选择付款期限',
                jyPassword: '',
                minLimit: '',
                maxLimit: '',
                number: '',
                premiseRate: ''
            },
            payType: [],
            time: [{
                name: '15'
            }, {
                name: '30'
            }, {
                name: '45'
            }, {
                name: '60'
            }],
            show1: false,
            show2: true,
            show3: false,
            show4: false,
            show6: false,
            text: '固定价格下,无需输入溢价',
            priceText: '请输入交易价格',
            marketPrice: [],
            marketPrices: '',
            coinType: '',
            coin: '选择币种',
            coinId: {},
            pay: [],
            payText: '请选择付款方式'
        }
    },
    components: {
        navBar
    },
    created() {
        this.getMarketPrice()
        this.getCanChoose()
        if (store.state.country == '中国') {
            this.coinType = 'CNY'
            this.form.country = store.state.country
        } else  {
            this.coinType = 'USD'
            this.form.country = store.state.country
        }
        if (this.$route.query.id) {
            this.getInfo()
        }
    },
    methods: {
        //查看详情
        async getInfo() {
            const res = await this.$http.post(this.host + '/otc/advertise/detail', { id: this.$route.query.id })
            this.form.minLimit = res.data.data.minLimit
            this.form.maxLimit = res.data.data.maxLimit
            this.form.timeLimit = res.data.data.timeLimit
            this.form.number = res.data.data.number
            this.form.price = res.data.data.price
            this.form.premiseRate = res.data.data.premiseRate
            this.form.priceType = res.data.data.priceType
            this.form.country = res.data.data.country.zhName
            let pass = {}
            this.coin = res.data.data.coinName
            pass['coin.id'] = res.data.data.coinId
            this.coinId = pass
            this.pay.push(res.data.data.payMode)
            if (res.data.data.country.zhName == '中国') {
                this.coinType = 'CNY'
            } else {
                this.coinType = 'USD'
            }
        },
        //选择币种事件
        openCoin() {
            this.show1 = true
        },
        chooseCoin(item) {
            let pass = {}
            if (this.coin == '选择币种') {
                this.coin = item.name
                pass['coin.id'] = item.id
            }
            this.coinId = pass
            this.marketPrices = item.marketPrice
            this.show1 = false

        },
        choosePayType(name) {
            this.pay.push(name)
            this.show3 = false
        },
        choosePay() {
            this.show3 = true
        },
        chooseTime(name) {
            this.form.timeLimit = name
            this.show4 = false
        },
        chooseTimeS() {
            this.show4 = true
        },
        //地区选择
        goCountry() {
            this.$router.push({ name: 'country' })
        },
        change(val) {
            console.log("11", val);
            if (val == 0) {
                this.text = '固定价格下,无需输入溢价'
                this.show2 = true
                this.priceText = '请输入交易价格'
                this.show6 = false
            } else {
                this.text = '根据市场价的溢出比例'
                this.show2 = false
                this.show6 = true
                this.priceText = '根据市场价和溢价自动计算'

            }
        },
        input(val) {
            this.form.price = (Number(this.marketPrices) * (Number(val) / 100) + Number(this.marketPrices)).toFixed(2)
        },
        //获取市场价格
        getMarketPrice() {
            this.$mts.post({
                url: 'otc/coin/all',
                data: {
                }, success: (response) => {
                    this.marketPrice = response.data.data
                }
            })
        },
        //发布出售
        submit() {
            if (this.$route.query.id) {
                if ((this.form.premiseRate < 1 || this.form.premiseRate > 20) && this.show2 != true) {
                    this.$toast("溢价区间在1-20")
                } else if (this.form.minLimit < 100) {
                    this.$toast("最小量不能低于100")
                } else if (this.form.maxLimit < this.form.minLimit) {
                    this.$toast("最大量不能小于最小量")
                } else {
                    let data = {
                        ...this.form,
                        ...this.coinId,
                        advertiseType: 1,
                        auto: 0,
                        remark: '',
                        autoword: '',
                        'pay[]': this.pay.join(','),
                        id: this.$route.query.id
                    }
                    this.$http.post(this.host + '/otc/advertise/update', data).then(res => {
                        if (res.data.code == 500) {
                            this.$toast(res.data.message)
                        } else {
                            this.$toast(res.data.message)
                            this.$router.push('/otc')
                        }
                    }).catch(err => {
                        this.$toast(err)
                    })
                }
            } else {
                if ((this.form.premiseRate < 1 || this.form.premiseRate > 20) && this.show2 != true) {
                    this.$toast("溢价区间在1-20")
                } else if (this.form.minLimit < 100) {
                    this.$toast("最小量不能低于100")
                } else if (this.form.maxLimit < this.form.minLimit) {
                    this.$toast("最大量不能小于最小量")
                } else {
                    let data = {
                        ...this.form,
                        ...this.coinId,
                        advertiseType: 1,
                        auto: 0,
                        remark: '',
                        autoword: '',
                        'pay[]': this.pay.join(',')
                    }
                    this.$http.post(this.host + '/otc/advertise/create', data).then(res => {
                        if (res.data.code == 500) {
                            this.$toast(res.data.message)
                        } else {
                            this.$toast(res.data.message)
                            this.$router.push('/otc')
                        }
                    }).catch(err => {
                        this.$toast(err)
                    })
                }
            }

        },
        //获取可以选择的支付方式
        async getCanChoose() {
            const res = await this.$http.post(this.host + '/uc/approve/account/setting')
            if (res.data.data.alipay) {
                this.payType.push({
                    name: '支付宝'
                })
            } 
            if (res.data.data.bankInfo) {
                this.payType.push({
                    name: '银联'
                })
            } 
            if (res.data.data.swift) {
                this.payType.push({
                    name: 'SWIFT'
                })
            } 
            if(res.data.data.wechatPay) {
                this.payType.push({
                    name: '微信'
                })
            }

        }
    }
}
</script>

<style scoped>
::v-deep .van-button__text {
    color: #fff;
    font-size: 4.5802vw;
    letter-spacing: .5089vw;
}


.coin {
    width: 100%;
    text-align: center;
    height: 12.2137vw;
    line-height: 12.2137vw;
    border-bottom: .2545vw solid #ccc;
}

.price {
    width: 91.6031vw;
    height: 6.6158vw;
    color: red;
    text-align: right;
    line-height: 6.6158vw;
}

::v-deep .van-field__control {
    border: none !important;
    height: 7.1247vw;
}

::v-deep .van-cell {
    height: 12.7226vw !important;
}

::v-deep .van-cell__title {
    color: #000 !important;
}

::v-deep .van-cell__value {
    color: #000 !important;
}
</style>